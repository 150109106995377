// @flow

import React from 'react';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import SpotiselfContent from '../components/ProjectsContent/SpotiselfContent/SpotiselfContent';

const SpotiselfPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      Brandbook for Spotiself – new gamified career modelling tool created by a team of psychologists and researchers to
      help people analyse their personal qualities and adjust potential career paths accordingly. Logotype variations.
      Brand colours and proportions. Fonts. Graphic and UI elements. Icons. Pattern.
      <br/><br/>
      Made with a team in ALL CAPS agency.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <SpotiselfContent/>
    </ProjectLayout>
  );
};

export default SpotiselfPage;
